



      .xbayy {
   margin: 40px;
   transform: scaleY(0.94)
        /* height: 100%;
        width: 100%; */
        /* position: relative; */
      }

      .xbaxx {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        /* height: 100%;
        width: 100%; */
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }






      .canvas1 {

      display: block;
      position: relative;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: auto;
      background-color: rgba(0, 0, 0, 0);
      width: auto;
    height: 1060.00px;
    left: 0px;
    top: 0px;
    right: 0px;

}

.canvas1 .background {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(0, 0, 0, 0);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(0, 0, 0, 0);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(0, 0, 0, 0);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1369.18px;
    height: 1064.70px;
    left: -6.69px;
    top: -4.39px;

}

.canvas1 .pattern850ce7b5 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1362.57px;
    height: 1063.85px;
    left: 0.00px;
    top: -1.77px;

}

.canvas1 .pattern850ce7b5 .pattern {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/pattern.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 0.6000000238418579;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(0, 0, 0, 0);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(0, 0, 0, 0);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(0, 0, 0, 0);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1361.57px;
    height: 1062.85px;
    left: -0.50px;
    top: -0.50px;

}

.canvas1 .lines {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1292.35px;
    height: 941.34px;
    left: 51.26px;
    top: 60.45px;

}

.canvas1 .lines .rectangle233720405 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(246, 218, 124, 1);
      border-bottom-style: solid;
      border-bottom-width: 6.00px;
      border-left-color: rgba(246, 218, 124, 1);
      border-left-style: solid;
      border-left-width: 6.00px;
      border-right-color: rgba(246, 218, 124, 1);
      border-right-style: solid;
      border-right-width: 6.00px;
      border-top-color: rgba(246, 218, 124, 1);
      border-top-style: solid;
      border-top-width: 6.00px;
      border-top-left-radius: 1.00px;
      border-top-right-radius: 1.00px;
      border-bottom-left-radius: 1.00px;
      border-bottom-right-radius: 1.00px;
      box-shadow: none;
      width: 1292.35px;
    height: 941.34px;
    left: 0.00px;
    top: 0.00px;

}

.canvas1 .lines .rectangle3 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(234, 194, 100, 1);
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(0, 0, 0, 0);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(0, 0, 0, 0);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(0, 0, 0, 0);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 1.00px;
      border-top-right-radius: 1.00px;
      border-bottom-left-radius: 1.00px;
      border-bottom-right-radius: 1.00px;
      box-shadow: none;
      width: 252.75px;
    height: 2.73px;
    left: 712.26px;
    top: 864.43px;

}

.canvas1 .lines .rectangle2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(234, 194, 100, 1);
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(0, 0, 0, 0);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(0, 0, 0, 0);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(0, 0, 0, 0);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 1.00px;
      border-top-right-radius: 1.00px;
      border-bottom-left-radius: 1.00px;
      border-bottom-right-radius: 1.00px;
      box-shadow: none;
      width: 252.75px;
    height: 2.73px;
    left: 325.00px;
    top: 864.43px;

}

.canvas1 .lines .rectangle1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(234, 194, 100, 1);
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(0, 0, 0, 0);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(0, 0, 0, 0);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(0, 0, 0, 0);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 1.00px;
      border-top-right-radius: 1.00px;
      border-bottom-left-radius: 1.00px;
      border-bottom-right-radius: 1.00px;
      box-shadow: none;
      width: 693.91px;
    height: 2.73px;
    left: 298.05px;
    top: 557.27px;

}

.canvas1 .rightSideShape {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 287.82px;
    height: 742.66px;
    left: 1083.04px;
    top: -7.19px;

}

.canvas1 .rightSideShape .shape336b546fb {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(254, 217, 104, 1);
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 281.32px;
    height: 737.96px;
    left: -1.00px;
    top: 5.70px;

}

.canvas1 .rightSideShape .shape29d213230 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 260.82px;
    height: 633.20px;
    left: 21.67px;
    top: 6.71px;

}

.canvas1 .rightSideShape .shape172bbdd21 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(234, 194, 100, 1);
          stroke-width: 5.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 217.54px;
    height: 619.42px;
    left: 70.29px;
    top: 0.00px;

}

.canvas1 .ribbon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 426.98px;
    height: 80.64px;
    left: 468.00px;
    top: 299.52px;

}

.canvas1 .ribbon .shape1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(254, 217, 104, 1);
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 428.98px;
    height: 82.64px;
    left: -1.00px;
    top: -1.00px;

}

.canvas1 .certificate {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(-0.00deg);
    -webkit-transform: rotate(-0.00deg);
    transform: rotate(-0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 0.00px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 82.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Outfit-Thin, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 468.00px;
    height: 109.00px;
    left: 447.00px;
    top: 214.09px;

}

.canvas1 .ofDonation {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 0.00px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 31.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Outfit-Light, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 204.00px;
    height: 41.00px;
    left: 579.00px;
    top: 336.80px;

}

.canvas1 .thisCertificateIsProudlyPresentedTo {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 31.00px;
      letter-spacing: 0.00px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 25.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Outfit-Light, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 508.00px;
    height: 33.00px;
    left: 427.00px;
    top: 407.74px;

}

.canvas1 .group1 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 513.06px;
    height: 98.97px;
    left: 410.26px;
    top: 877.38px;

}

.canvas1 .group1 .signature {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 2.50px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 25.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Outfit-Regular, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 148.00px;
    height: 33.00px;
    left: 0.00px;
    top: 81.47px;

}

.canvas1 .group1 .date {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 2.40px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 24.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Outfit-Regular, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 62.00px;
    height: 32.00px;
    left: 425.06px;
    top: 81.00px;

}

.canvas1 .group1 .x000000 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 2.40px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 24.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Outfit-Regular, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 114.00px;
    height: 32.00px;
    top: 15.00px;
    right: 0.00px;

}

.canvas1 .yohannesZemene {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 0.00px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 80.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Exmouth, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 636.00px;
    height: 106.00px;
    left: 363.00px;
    top: 566.60px;

}

.canvas1 .amount25000Etb {
    position: relative;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 2.00px;
      letter-spacing: 0.00px;
      color: rgba(46, 19, 9, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 60.00px;
      font-weight: 400;
    font-style: normal;
      font-family: Exmouth, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 562.00px;
    height: 80.00px;
    left: 384.00px;
    top: 727.77px;

}

.canvas1 .maskGroup1 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/maskGroup1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1365.00px;
    height: 1062.00px;
    left: 0.00px;
    top: 0.00px;

}

.canvas1 .maskGroup1 .leftSideShape {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 560.18px;
    height: 1180.34px;
    left: -169.00px;
    top: -59.52px;

}

.canvas1 .maskGroup1 .leftSideShape .shape3 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(254, 217, 104, 1);
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 470.66px;
    height: 1144.97px;
    left: 90.51px;
    top: 3.73px;

}

.canvas1 .maskGroup1 .leftSideShape .shape2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 542.63px;
    height: 1182.34px;
    left: -1.00px;
    top: -1.00px;

}

.canvas1 .maskGroup1 .leftSideShape .shape1a3aafeb8 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(246, 218, 124, 1);
          stroke-width: 5.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 496.75px;
    height: 1163.88px;
    left: 0.46px;
    top: 9.35px;

}

.canvas1 .maskGroup1 .rectangle142e83dea {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.canvas1 .img20230325121704017 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/img20230325121704017.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 438.89px;
    height: 246.88px;
    left: -48.15px;
    top: 60.45px;

}
