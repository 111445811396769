.left_anime {


    position: relative;
    animation-name: example_1;
    animation-duration: 6s;
    animation-delay: 1s;
  }

  @keyframes example_1 {
    0%   {  left:-25%; top:0px;}
    100%  {  left:0px; top:0px;}

  }


  .right_anime {


    position: relative;
    animation-name: example_2;
    animation-duration: 6s;
    animation-delay: 1s;
  }

  @keyframes example_2 {
    0%   {  left:25%; top:0px;}
    100%  {  left:0px; top:0px;}

  }


  .gallery_anim {


    position: relative;
    animation-name: example_3;
    animation-duration: 6s;
    animation-delay: 1s;
  }

  @keyframes example_3 {
    0%   {  scale: 0.8;}
    100%  {  scale: 1;}

  }