:root {
    --color-default: #364d59;
    --color-primary: #feb900;
    --color-secondary: #52565e;
}


.bbody {
    color: black;
    display: table;
    font-family: Georgia, serif;
    font-size: 24px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 2%;
    margin-bottom: 3%;
    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
    padding: 0.5rem;
    overflow: hidden;

    width: "297px";
    height: "210px";


}

.containerm {
    background-color: rgb(231, 231, 231);
    /* border: 20px solid  #9f7928; */

    width: "297px";
    height: "210px";

   display: table-cell;
    vertical-align: middle;
    align-items: center;
}

.logo {
    color: #BF953F;
}

.marquee {
    color: #BF953F;

    font-size: 48px;
    margin: 20px;
}

.assignment {
    margin: 20px;
}

.person {
    border-bottom: 2px solid #BF953F;
    color: #BF953F;
    font-size: 32px;
    font-style: italic;
    margin: 20px auto;
    width: 400px;
}

.reason {
    margin: 20px;
}